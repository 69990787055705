import React, { useState } from 'react';
import './App.css';
import './Style.css';

function App() {
  const [content, setContent] = useState('');

  const handleContentChange = (type) => {
    setContent(type);
  };

  return (
    <>
      <span className='nome-escola'>Escola Senai Ricardo Lerner</span>
      <div className="App">
        <img className="logo-central" src="logo-senai.png" />

        <div className="container-conteudo">
          <div className="titulo">
            <h1>Guia de Suporte para o Auditório</h1>
          </div>
          <span>Selecione uma opção</span>
          <div className="button-container">
            <button onClick={() => handleContentChange('notebook')}>Notebook</button>
            <button onClick={() => handleContentChange('computador')}>Computador</button>
          </div>

          <hr />

          {content === 'notebook' && (
            <div>
              <div className='containerTexto'>
                <p>Para usar o notebook do auditório:</p>
                <ol>
                  <li>Ligue o notebook que se encontra na frente do auditório.</li>
                  <br />
                  <li>Ative a máquina no rack do auditório pressionando o botão ilustrado nas imagens abaixo:</li>
                  <br />
                  <img className='imagemTexto' src="imagem1-notebook.png" />
                  <br />
                  <img className='imagemTexto' src="imagem2-notebook.png" />
                  <br />
                  <li>Utilize o controle remoto de dois botões para alternar entre as opções "Computador" e "Notebook". Selecione "Notebook".</li>
                  <br />
                  <img className='imagemTexto' src="imagem3-notebook-controle.jpg" />
                </ol>
                <p>Com esses passos, o notebook estará pronto para uso em apresentações.</p>
              </div>

              <button onClick={() => handleContentChange('computador')}>Ir para Computador</button>
            </div>
          )}

          {content === 'computador' && (
            <div>
              {/* Conteúdo para Computador */}
              <p>Informações para computador...</p>
              {/* Botão para alternar para Notebook */}
              <button onClick={() => handleContentChange('notebook')}>Ir para Notebook</button>
            </div>
          )}

          <hr />
          <img className="logo-central" src="logo-senai.png" />
          <div style={{ textAlign: "center", marginTop: "-50px" }}>
            <p>Escola Senai Ricardo Lerner</p>
            <p>Todos os Direitos Reservados</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
